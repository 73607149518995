import { Card, Container, Grid } from '@mui/material';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import { PATH_CLIENT } from '../routes/paths';
import { fStringCapitalizeFirstLetter } from '../utils/formatString';

import ThirdPartyDataForm from '../components/_dashboard/thirdPartyDataSettings/ThirdPartyDataForm.jsx';

const DetailCard = styled(Card)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  textAlign: 'left'
}));

export default function ThirdPartySettings() {
  const { t } = useTranslation();
  const title = fStringCapitalizeFirstLetter(t('third_party_settings'));

  return (
    <Page title={title}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={title}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: title }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <DetailCard sx={{ p: 3 }}>
              <ThirdPartyDataForm />
            </DetailCard>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
