import apiKeySlice from '@/store/apiKeySlice';
import engineSlice from '@/store/engineSlice';
import fileSlice from '@/store/fileSlice';
import fuelSlice from '@/store/fuelSlice';
import labelSlice from '@/store/labelSlice';
import measurementSlice from '@/store/measurementSlice';
import messageSlice from '@/store/messageSlice';
import organizationSlice from '@/store/organizationSlice';
import reportSlice from '@/store/reportSlice';
import requestSlice from '@/store/requestSlice';
import shipSlice from '@/store/shipSlice';
import thirdPartySlice from '@/store/thirdPartySlice';
import translationSlice from '@/store/translationSlice';
import userSlice from '@/store/user-slice';
import wizardSlice from '@/store/wizard-slice';
import type { Ship } from '@/types/ship.ts';
import type { UserSliceType } from '@/types/user';
// import type { UserSlice } from '@/types/user';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// Typing for converted slices
interface WizardSlice {
  wizardShip: Ship | null;
  setWizardShip: (wizardShip: Ship | null) => void;
}

// UnknownSlices state for js slices
interface UnknownSlices {
  // biome-ignore lint: Required for JS slice compatibility
  [key: string]: any | ((...args: any[]) => any);
}

// Combine known and unknown types
export type StoreState = WizardSlice & UserSliceType & UnknownSlices;

const useStore = create<StoreState>()(
  persist(
    (set, get) => ({
      ...engineSlice(set, get),
      ...fuelSlice(set, get),
      ...userSlice(set, get),
      ...shipSlice(set, get),
      ...fileSlice(set, get),
      ...labelSlice(set, get),
      ...messageSlice(set),
      ...organizationSlice(set, get),
      ...requestSlice(set, get),
      ...reportSlice(set, get),
      ...translationSlice(set),
      ...wizardSlice(set),
      ...measurementSlice(set, get),
      ...thirdPartySlice(set),
      ...apiKeySlice(set, get)
    }),
    {
      name: 'emissie',
      partialize: (state) => ({
        authenticated: state.authenticated,
        isAdmin: state.isAdmin,
        user: state.user
      })
    }
  )
);

export default useStore;
