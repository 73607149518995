import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import ApiKeysList from '../components/_dashboard/apiKeys/ApiKeysList.jsx';
import { PATH_CLIENT } from '../routes/paths';
import { fStringCapitalizeFirstLetter } from '../utils/formatString';

export default function ApiSettings() {
  const { t } = useTranslation();
  const title = fStringCapitalizeFirstLetter(t('api_settings'));

  return (
    <Page title={title}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={title}
          links={[{ name: 'Dashboard', href: PATH_CLIENT.root }, { name: title }]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sx={{ width: '100%' }}>
            <ApiKeysList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
