import LabelRequest from '@/components/_dashboard/label-request/label-request';
import Login from '@/pages/login';
import Ship from '@/pages/ship';
import useStore from '@/store/use-store';
import { Navigate, useRoutes } from 'react-router-dom';
import RequestOverview from './components/_dashboard/admin/request/overview/RequestOverview';
import ShipOverview from './components/_dashboard/ship/ShipOverview';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import DashboardLayout from './layouts/dashboard';
import AcceptOrganizationInvitation from './pages/AcceptOrganizationInvitation';
import ApiSettings from './pages/ApiSettings.jsx';
import Help from './pages/Help';
import Label from './pages/Label';
import Logout from './pages/Logout';
import Organization from './pages/Organization';
import NotFound from './pages/Page404';
import QuarterlyReport from './pages/QuarterlyReport';
import RegisterWithInvite from './pages/RegisterWithInvite';
import ThirdPartySettings from './pages/ThirdPartySettings.jsx';
import AdminEngines from './pages/admin/AdminEngines';
import AdminFuels from './pages/admin/AdminFuels';
import AdminRequest from './pages/admin/AdminRequest';
import User from './pages/admin/User';
import UserCreate from './pages/admin/UserCreate';
import DistributedLabel from './pages/authority/DistributedLabel';
import ForgotPassword from './pages/forgot-password';
import Register from './pages/register';
import Request from './pages/request';
import ResetPassword from './pages/reset-password';
import { PATH_ADMIN, PATH_API, PATH_AUTHORITY, PATH_CLIENT } from './routes/paths';

export default function Router() {
  const authenticated = useStore((state) => state.authenticated);
  const { user } = useStore((state) => state);
  const isAdmin = user?.roles?.includes('ROLE_ADMIN');
  const isAuthority = user?.roles?.includes('ROLE_AUTHORITY');
  const isApi = user?.roles?.includes('ROLE_API');
  let baseRoute;
  if (isAdmin) {
    baseRoute = 'admin';
  } else if (isApi) {
    baseRoute = 'third-party/api-settings';
  } else {
    baseRoute = isAuthority ? 'authority' : 'client';
  }

  return useRoutes([
    {
      path: PATH_CLIENT.root,
      // eslint-disable-next-line no-nested-ternary
      element: authenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: PATH_CLIENT.root, element: <Navigate to="/client/ship" replace /> },
        { path: PATH_CLIENT.accept_invite, element: <AcceptOrganizationInvitation /> },
        { path: PATH_CLIENT.ship, element: <Ship /> },
        { path: PATH_CLIENT.ship_details, element: <ShipOverview /> },
        { path: PATH_CLIENT.ship_create, element: <ShipOverview /> },
        { path: PATH_CLIENT.hours, element: <QuarterlyReport /> },
        { path: PATH_CLIENT.label, element: <Label /> },
        { path: PATH_CLIENT.request, element: <Request /> },
        { path: PATH_CLIENT.organization, element: <Organization /> },
        { path: PATH_CLIENT.user, element: <User /> },
        { path: PATH_CLIENT.wizard, element: <LabelRequest /> },
        { path: PATH_CLIENT.third_party_settings, element: <ThirdPartySettings /> },
        { path: PATH_CLIENT.help, element: <Help /> }
      ]
    },
    {
      path: PATH_ADMIN.root,
      element: isAdmin ? <DashboardLayout /> : <Navigate to="/client" />,
      children: [
        { path: PATH_ADMIN.root, element: <Navigate to="/admin/requests" replace /> },
        { path: PATH_ADMIN.users, element: <User /> },
        { path: PATH_ADMIN.user_create, element: <UserCreate /> },
        { path: PATH_ADMIN.user, element: <UserCreate /> },
        { path: PATH_ADMIN.requests, element: <AdminRequest /> },
        { path: PATH_ADMIN.request, element: <RequestOverview /> },
        { path: PATH_ADMIN.engines, element: <AdminEngines /> },
        { path: PATH_ADMIN.fuels, element: <AdminFuels /> }
      ]
    },
    {
      path: PATH_AUTHORITY.root,
      element: isAuthority ? <DashboardLayout /> : <Navigate to="/client" />,
      children: [
        { path: PATH_AUTHORITY.root, element: <Navigate to="/authority/labels" replace /> },
        { path: PATH_AUTHORITY.labels, element: <DistributedLabel /> }
      ]
    },
    {
      path: PATH_API.root,
      element: isApi ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: PATH_API.root, element: <Navigate to="/" replace /> },
        { path: PATH_API.api_settings, element: <ApiSettings /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: 'forgot', element: <ForgotPassword /> },
        { path: 'verify/:token', element: <ResetPassword /> },
        { path: 'register', element: <Register /> },
        { path: 'accept_invite', element: <RegisterWithInvite /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to={`/${baseRoute}`} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
