import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useStore from '@/store/use-store';
import { toast } from 'react-toastify';

ApiKeyForm.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function ApiKeyForm({ onCancel }) {
  const { t } = useTranslation();
  const { createApiKey } = useStore((state) => state);
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [key, setKey] = useState(false);

  const ApiKeySchema = Yup.object().shape({
    name: Yup.string().required(t('required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: ''
    },
    validationSchema: ApiKeySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      createApiKey(values)
        .then((res) => {
          setKey(res.key);
          setCreationSuccess(true);
          toast.success(t('api.created'));
        })
        .catch((e) => toast.error(e.message));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  if (creationSuccess) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography sx={{ textAlign: 'left' }} variant="h6">
            {t('api.created')}
          </Typography>
          <Typography sx={{ textAlign: 'left', padding: 0 }} variant="body1">
            {t('api.this_is_your_key', { key })}
          </Typography>
          <Button
            sx={{ mr: 2, float: 'right', mt: 2 }}
            type="submit"
            variant="contained"
            color="success"
            onClick={() => onCancel()}
          >
            {t('api.back')}
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label={t('name')}
                {...getFieldProps('name')}
                error={Boolean(touched?.name && errors?.name)}
                helperText={touched?.name && errors?.name}
              />
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{ mr: 2 }}
                type="submit"
                variant="contained"
                color="error"
                onClick={() => onCancel()}
              >
                {t('cancel')}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {t('save')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
