import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useStore from '@/store/use-store';
import { toast } from 'react-toastify';
export default function ThirdPartyDataForm() {
  const { t } = useTranslation();
  const { updateThirdPartyData } = useStore((state) => state);
  const user = useStore((state) => state.user);
  const { getThirdParties, thirdParties, loadingThirdParties } = useStore((state) => state);
  useEffect(() => {
    getThirdParties();
  }, [getThirdParties]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: user.id,
      allowedThirdParties: user.allowedThirdParties,
      certificatesArePublic: user.certificatesArePublic
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      updateThirdPartyData(values)
        .then(() => {
          toast.success(t('saved'));
        })
        .catch(() => toast.error(t('thirdparty.error')));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, setFieldValue } = formik;

  const handleChange = (event) => {
    const { value } = event.target;
    // Find the object with the corresponding ID in the thirdparties array
    const selectedThirdParties = value.map((id) =>
      thirdParties.find((thirdParty) => thirdParty.id === id)
    );
    // Set the fieldValue with the selected third party
    setFieldValue('allowedThirdParties', selectedThirdParties);
  };
  const onCertificatesArePublicInputChanged = (e) => {
    setFieldValue('certificatesArePublic', e.target.checked);
  };
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              {loadingThirdParties && (
                <TextField disabled fullWidth value={t('api.loading_parties')} />
              )}
              {!loadingThirdParties && (
                <FormControl error={Boolean(touched.type && errors.type)}>
                  <InputLabel id="type-label">{t('api.allowed_third_party')}</InputLabel>
                  <Select
                    multiple
                    labelId="type-label"
                    placeholder={t('api.allowed_third_party')}
                    name="allowedThirdParties"
                    value={
                      formik.values.allowedThirdParties !== undefined
                        ? formik.values.allowedThirdParties.map((party) => party.id)
                        : []
                    }
                    error={Boolean(touched.type && errors.type)}
                    disabled={loadingThirdParties}
                    type="text"
                    onChange={handleChange}
                  >
                    {thirdParties.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.certificatesArePublic}
                    onChange={(event) => onCertificatesArePublicInputChanged(event)}
                    color="primary"
                  />
                }
                label={t('api.certificates_are_public')}
              />
            </Stack>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {t('save')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
