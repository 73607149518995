import { IconStyle, LabelStyle, SubLabelStyle } from '@/layouts/styles';
import useStore from '@/store/use-store';
import { validateFileSize } from '@/utils/validateFileArray';
import infoOutline from '@iconify/icons-eva/info-outline';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import UploadFile from '../../../upload/upload-file';

EngineForm.propTypes = {
  engine: PropTypes.object,
  ship: PropTypes.object.isRequired,
  submitCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default function EngineForm({ engine, ship, submitCallback, onCancel }) {
  const { t } = useTranslation();
  const isEdit = !!engine;
  const { createEngine, updateEngine, savingEngine } = useStore((state) => state);
  const { uploadFile, savingFile } = useStore((state) => state);
  const { createEngineHourReading, savingEngineHourReading } = useStore((state) => state);
  const { getEngineTypes, loadingEngineTypes, engineTypes } = useStore((state) => state);
  const { getEngineTitles, loadingEngineTitles, engineTitles } = useStore((state) => state);
  // const { getFuelTypes, loadingFuelTypes, fuelTypes } = useStore((state) => state);
  const maxUploadSize = import.meta.env.VITE_APP_MAX_UPLOAD;

  useEffect(() => {
    getEngineTypes();
    getEngineTitles();
    // getFuelTypes();
  }, [getEngineTypes, getEngineTitles]);

  const EngineSchema = Yup.object().shape({
    edit: Yup.boolean(),
    engine: Yup.object().shape({
      power: Yup.number().required(t('required')),
      name: Yup.string().required(t('required')).trim(),
      title: Yup.lazy((_, { parent }) => {
        if (parent.name === '_alt') {
          return Yup.string().required(t('required'));
        }
        return Yup.string();
      }),
      installationStatements: Yup.array()
        .min(1, t('required'))
        .test('is-big-file', t('max_file_size', { size: maxUploadSize }), validateFileSize),
      measurementReports: Yup.array()
        .min(1, t('required'))
        .test('is-big-file', t('max_file_size', { size: maxUploadSize }), validateFileSize)
    }),
    engineOperatingHours: Yup.object().shape({
      hours: Yup.string().when(['edit'], {
        is: (edit) => edit === false,
        then: (Schema) => Schema.required(t('required')),
        otherwise: (Schema) => Schema.nullable().notRequired()
      }),
      proof: Yup.mixed().when(['edit'], {
        is: (edit) => edit === false,
        then: (Schema) =>
          Schema.required(t('required')).test(
            'fileSize',
            t('max_file_size', { size: maxUploadSize }),
            (file) => {
              if (!file) return true;
              return file.size <= maxUploadSize;
            }
          ),
        otherwise: (Schema) => Schema.nullable().notRequired()
      })
    })
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      edit: isEdit,
      engine: {
        id: engine?.id || '',
        name: engine?.name?.id || '',
        power: engine?.power || '',
        title: engine?.title || '',
        type: engine?.type?.id || '',
        postTreatment: engine?.postTreatment || false,
        fuelType: engine?.fuelType?.id || '',
        installationStatements: engine?.installationStatements || [],
        measurementReports: engine?.measurementReports || [],
        ship: ship.id
      },
      engineOperatingHours: {
        hours: '',
        proof: null,
        engine: engine?.id || ''
      }
    },
    validationSchema: EngineSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const { engine, engineOperatingHours } = values;
      let executable = updateEngine;
      if (engine.id === '') {
        delete engine.id;
        executable = createEngine;
      }

      if (engine.name !== '_alt') delete engine.title;
      if (engine.name === '_alt') delete engine.name;
      if (engine.fuelType) engine.fuelType = Number.parseInt(values.engine.fuelType, 10);
      if (engine.name) engine.name = Number.parseInt(values.engine.name, 10);
      if (engine.type) engine.type = Number.parseInt(values.engine.type, 10);
      try {
        let promises = [];
        for (let i = 0; i < values.engine?.installationStatements.length; i += 1) {
          promises.push(uploadFile(values.engine.installationStatements[i]));
        }

        const newEngine = { ...engine };
        newEngine.installationStatements = [];

        const installationStatementFiles = await Promise.all(promises);
        installationStatementFiles.map((file) => {
          newEngine.installationStatements.push(file.fileName);
          return file;
        });

        promises = [];
        newEngine.measurementReports = [];
        for (let i = 0; i < values.engine?.measurementReports.length; i += 1) {
          promises.push(uploadFile(values.engine.measurementReports[i]));
        }
        const measurementReportsFiles = await Promise.all(promises);
        measurementReportsFiles.map((file) => {
          newEngine.measurementReports.push(file.fileName);
          return file;
        });
        executable(newEngine).then((engine) => {
          if (!isEdit) {
            engineOperatingHours.engine = engine.id;
            return createEngineHourReading(engine?.id, engineOperatingHours).then((res) =>
              submitCallback(res)
            );
          }
          return submitCallback(engine);
        });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } =
    formik;
  console.log(errors);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('engineOperatingHours.proof', file);
      }
    },
    [setFieldValue]
  );

  const handleRemove = useCallback(() => {
    setFieldValue('engineOperatingHours.proof', null);
  }, [setFieldValue]);

  const handleStatementDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const totalFiles = [...values.engine.installationStatements, ...newFiles];
      setFieldValue('engine.installationStatements', totalFiles);
    },
    [setFieldValue, values]
  );

  const handleStatementRemoveAll = () => {
    setFieldValue('engine.installationStatements', []);
  };

  const handleStatementRemove = (file) => {
    const filteredItems = values.engine?.installationStatements.filter((_file) => _file !== file);
    setFieldValue('engine.installationStatements', filteredItems);
  };

  const handleMeasurementDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const totalFiles = [...values.engine.measurementReports, ...newFiles];
      setFieldValue('engine.measurementReports', totalFiles);
    },
    [setFieldValue, values]
  );

  const handleMeasurementRemoveAll = () => {
    setFieldValue('engine.measurementReports', []);
  };

  const handleMeasurementRemove = (file) => {
    const filteredItems = values.engine?.measurementReports.filter((_file) => _file !== file);
    setFieldValue('engine.measurementReports', filteredItems);
  };

  function getEngineTypeInfo(type) {
    if (!type) return null;
    const engineType = engineTypes.filter(
      (obj) => Number.parseInt(obj.id, 10) === Number.parseInt(type, 10)
    );
    if (!engineType || engineType.length === 0) return null;
    return (
      <Stack direction="row">
        <IconStyle icon={infoOutline} />
        <Typography variant="body2">{engineType[0].description}</Typography>
      </Stack>
    );
  }

  const isPostTreatmentOptional = (engineType) => {
    const type = engineTypes.find((e) => e.id === Number.parseInt(engineType, 10));
    if (type) {
      if (!type.postTreatmentOptional && values.postTreatment)
        setFieldValue('engine.postTreatment', false);
      return type.postTreatmentOptional;
    }
    if (values.postTreatment) setFieldValue('engine.postTreatment', false);
    return false;
  };

  function getFuelTypes(type) {
    if (!type) return [];
    const engineType = engineTypes.find(
      (obj) => Number.parseInt(obj.id, 10) === Number.parseInt(type, 10)
    );
    if (!engineType) return [];
    return engineType.fuelTypes;
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <Stack spacing={3}>
              {loadingEngineTitles && (
                <TextField disabled fullWidth value={t('engine.loading_names')} />
              )}
              {!loadingEngineTitles && (
                <TextField
                  select
                  fullWidth
                  label={t('engine.title')}
                  placeholder={t('engine.title')}
                  {...getFieldProps('engine.name')}
                  SelectProps={{ native: true }}
                  error={Boolean(touched.engine?.name && errors.engine?.name)}
                  helperText={touched.engine?.name && errors.engine?.name}
                >
                  <option>{t('select_engine')}</option>
                  {engineTitles.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))}
                  <option key="diff" value={t('_alt')}>
                    {t('alternative')}
                  </option>
                </TextField>
              )}
              {!loadingEngineTitles && values.engine.name === '_alt' && (
                <TextField
                  fullWidth
                  label={t('engine.title')}
                  {...getFieldProps('engine.title')}
                  error={Boolean(touched.engine?.title && errors.engine?.title)}
                  helperText={touched.engine?.title && errors.engine?.title}
                />
              )}
              <TextField
                fullWidth
                label={t('engine.power')}
                {...getFieldProps('engine.power')}
                error={Boolean(touched.engine?.power && errors.engine?.power)}
                helperText={touched.engine?.power && errors.engine?.power}
                type="number"
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    (e) => {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
              {loadingEngineTypes && (
                <TextField disabled fullWidth value={t('engine.loading_types')} />
              )}
              {!loadingEngineTypes && (
                <TextField
                  select
                  fullWidth
                  label={t('engine.type')}
                  placeholder={t('engine.type')}
                  {...getFieldProps('engine.type')}
                  SelectProps={{ native: true }}
                  error={Boolean(touched.engine?.type && errors.engine?.type)}
                  helperText={touched.engine?.type && errors.engine?.type}
                >
                  <option value="" />
                  {engineTypes.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.code}
                    </option>
                  ))}
                </TextField>
              )}
              {getEngineTypeInfo(values.engine.type)}
              {isPostTreatmentOptional(values.engine.type) && (
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(event) =>
                        setFieldValue('engine.postTreatment', event.target.checked)
                      }
                      checked={values.engine.postTreatment}
                    />
                  }
                  label={
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {t('engine.post_treatment')}
                    </Typography>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'left' }}
                />
              )}
              {values.engine.type !== '' && (
                <TextField
                  select
                  fullWidth
                  label={t('engine.fuel_type')}
                  placeholder={t('engine.fuel_type')}
                  {...getFieldProps('engine.fuelType')}
                  SelectProps={{ native: true }}
                  error={Boolean(touched.fuelType && errors.fuelType)}
                  helperText={touched.fuelType && errors.fuelType}
                >
                  <option value="" />
                  {getFuelTypes(values.engine.type).map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.description} {option.unitOfMeasurement}
                    </option>
                  ))}
                </TextField>
              )}
              <Box sx={{ mb: 5 }}>
                <LabelStyle>{t('engine.statement')}</LabelStyle>
                <SubLabelStyle>{t('engine.statement_explanation')}</SubLabelStyle>
                <UploadFile
                  singleFile={false}
                  maxSize={maxUploadSize}
                  accept="image/*,.pdf,.docx,.doc"
                  files={values.engine?.installationStatements}
                  onDrop={handleStatementDrop}
                  onRemove={handleStatementRemove}
                  onRemoveAll={handleStatementRemoveAll}
                  error={Boolean(
                    touched.engine?.installationStatements && errors.engine?.installationStatements
                  )}
                  subtext={t('engine.statement_explanation')}
                />
                {touched.engine?.installationStatements &&
                  errors.engine?.installationStatements && (
                    <FormHelperText error sx={{ px: 2 }}>
                      {touched.engine?.installationStatements &&
                        errors.engine?.installationStatements}
                    </FormHelperText>
                  )}
              </Box>

              {!isEdit && (
                <>
                  <TextField
                    fullWidth
                    label={t('engine.current_hours')}
                    {...getFieldProps('engineOperatingHours.hours')}
                    error={Boolean(
                      touched.engineOperatingHours?.hours && errors.engineOperatingHours?.hours
                    )}
                    helperText={
                      touched.engineOperatingHours?.hours && errors.engineOperatingHours?.hours
                    }
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                  <Box sx={{ mb: 5 }}>
                    <LabelStyle>{t('engine.proof')}</LabelStyle>
                    <UploadFile
                      maxSize={maxUploadSize}
                      accept="image/*,.pdf,.docx,.doc"
                      file={values.engineOperatingHours.proof}
                      onDrop={handleDrop}
                      error={Boolean(
                        touched.engineOperatingHours?.proof && errors.engineOperatingHours?.proof
                      )}
                      onRemove={handleRemove}
                      subtext={t('engine.proof_explanation')}
                      singleFile
                    />
                    {touched.engineOperatingHours?.proof && errors.engineOperatingHours?.proof && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.engineOperatingHours?.proof && errors.engineOperatingHours?.proof}
                      </FormHelperText>
                    )}
                  </Box>
                </>
              )}

              <Box sx={{ mb: 5 }}>
                <LabelStyle>
                  {values.engineOperatingHours.hours > 20000
                    ? t('engine_report.proof')
                    : t('engine_report.declaration')}
                </LabelStyle>
                <UploadFile
                  maxSize={maxUploadSize}
                  accept="image/*,.pdf,.docx,.doc"
                  files={values.engine?.measurementReports}
                  onDrop={handleMeasurementDrop}
                  onRemove={handleMeasurementRemove}
                  onRemoveAll={handleMeasurementRemoveAll}
                  error={Boolean(
                    touched.engine?.measurementReports && errors.engine?.measurementReports
                  )}
                  subtext={
                    values.engineOperatingHours.hours > 20000
                      ? t('engine_report.proof_explanation')
                      : t('engine_report.declaration_explanation')
                  }
                />
                {touched.engine?.measurementReports && errors.engine?.measurementReports && (
                  <FormHelperText error sx={{ px: 2 }}>
                    {touched.engine?.measurementReports && errors.engine?.measurementReports}
                  </FormHelperText>
                )}
              </Box>
            </Stack>
            <Grid container spacing={3} padding={3}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    onClick={() => onCancel()}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || savingEngine || savingEngineHourReading || savingFile}
                  >
                    {isEdit ? t('edit') : t('save')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
